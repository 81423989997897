.HVideo {
    position: relative;
    width: 100%;
    max-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.HVideo video,
.HVideo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}