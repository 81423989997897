.Services {
    padding: 80px 0 150px;
    position: relative;
    background: url('../../../assets/images/serviceBg.png') center center / cover no-repeat;
}

.Services::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #122747d1, #0b1d34);
}
@media(max-width:767px){
    .Services{
        padding: 40px 0;
    }
}