.ContactLocation {
    padding: 0px 15px 80px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.locationTitle {
    display: flex;
    align-items: center;
    gap: 10px;
}

.locationTitle span {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.125px;
}