.contact-form {
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 150px;
}

.form-field {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 8px;
    color: #AAA;
    font-size: 27.137px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.339px;
    text-transform: capitalize;
}

input,
textarea {
    outline: none;
    padding: 12px;
    font-size: 16px;
    color: #fff;
    border-radius: 6.784px;
    border: 0.678px solid #777;
    background: #343333;
}

textarea {
    resize: none !important;
    /* scale: n; */
}

.error {
    color: red;
    font-size: 18px;
    margin-top: 4px;
    height: 20px;
}

.submit-button {
    border: none;
    outline: none;
    width: fit-content;
    color: #145693;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 58.344px;
    letter-spacing: 0.339px;
    text-transform: capitalize;
    border-radius: 6.784px;
    background: #F1F7FF;
    padding: 0px 60px;
    transition: all .3s;
    margin-top: 24px;
}

.submit-button:hover {
    background-color: #0056b3;
    color: #fff;
}

@media(max-width:767px) {

    input,
    textarea {
        padding: 6px 12px;
    }

    label {
        font-size: 18px;
    }
}

@media(max-width:500px) {
    label {
        font-size: 14px;
    }

    .submit-button {
        font-size: 18px;
        line-height: 40px;
        padding: 0 20px;
        margin-top: 15px;
    }
}