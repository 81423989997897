footer {
    position: relative;
    padding: 80px 0;
    position: relative;
    background: linear-gradient(to top, #122747d1, #0b1d34);
    border-top: 1px solid rgba(255, 255, 255, 0.46);
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.46); */
}

footer::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.46);
}

.footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
    width: 100%;
}

.footerTop {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.footerIcon {
    width: 203px;
    height: 168px;
}

.footerIcon img {
    max-width: 100%;
    object-fit: contain;
}

.footerList {
    justify-self: end;
    /* align-self: self-end; */
    /* max-width: 70%; */
    flex-basis: 60%;
}

.serviceBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 64.474px;
    border: 1px solid rgba(255, 255, 255, 0.76);
    padding: 12px 10px;
    margin-bottom: 20px;

    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    /* text-wrap:nowrap; */
}

.footerBottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.socialLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
}

.contactFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.contactFooter span {
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.contactFooter .phone {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #fff;
}

@media(max-width:767px) {

    .footerTop,
    .footerBottom {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 40px;
    }
        .contactFooter .phone {
            width: 30px;
            height: 30px;
        }
    
        .contactFooter .phone svg {
            width: 14px;
            height: 20px;
        }
    
        .contactFooter span {
            font-size: 16px;
        }
    
        .socialLinks a svg {
            width: 40px;
            height: 40px;
        }
        .footerContainer{
            gap: 25px;
        }
}

@media(max-width:400px) {
    .serviceBtn {
        font-size: 12px;
    }

    .footerIcon {
        width: 150px;
        height: 100px;
        margin-bottom: 20px;
    }

}