header {
    width: 100%;
    padding: 1.5rem 0;
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    z-index: 1000;
    transition: all .3s;
}

header.scrolled {
    background: linear-gradient(to left, rgba(18, 39, 71, 0.52), rgb(11, 29, 52, .5));
    backdrop-filter: blur(9.899999618530273px);
    padding: .75rem 0;
}

header .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoImg {
    width: 128px;
    height: 53px;
}

.logoImg img {
    max-width: 100%;
}

.rside {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.rside nav {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 66.5px;
    border: 1px solid #3061A4;
    background: rgba(217, 217, 217, 0.10);
    backdrop-filter: blur(9.899999618530273px);
    padding: 0 10px;
}

.rside nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
    transition: all 0.3s ease;
    max-width: 697px;
    overflow: hidden;
    /* padding-left: 30px !important; */
}

.rside nav ul li:first-child {
    margin-left: 20px;
}

.rside nav ul li:last-child {
    margin-right: 10px;
}

.ul_ar li:first-child {
    margin-right: 10px !important
}

.ul_ar li:last-child {
    margin-left: 20px !important;
}

.rside nav ul.hide {
    max-width: 0;
    opacity: 0;
    padding: 0;
    transition: all 0.3s ease;
}


.rside nav ul li a {
    text-decoration: none;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-wrap: nowrap;
    text-transform: capitalize;
}

.langugaeIcon {
    cursor: pointer;
}

@media(max-width:767px) {

    .rside {
        align-items: flex-start !important;
    }

    .containerHeader {
        align-items: initial !important;
    }

    .rside nav.show {
        flex-direction: column-reverse;
        padding: 20px 40px;
        border-radius: 12px;
    }

    .rside nav {
        flex-direction: column;
        overflow: hidden;
    }

    .rside nav ul {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* max-width: 100%; */
        max-height: 500px;
    }

    .rside nav ul li:first-child {
        margin-left: 0;
        margin-top: 20px;
    }

    .rside nav ul li:last-child {
        margin-right: 0;
    }

    .rside nav ul.hide {
        max-height: 0;
    }

    .rside nav ul li a {
        font-size: 16px;
    }
}

@media(max-width:430px) {
    header .containerHeader {
        gap: 20px;
    }

    .logoImg {
        width: 90px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }

    .rside nav.show {
        padding: 20px 30px;
    }

    .rside nav ul li a {
        font-size: 14px;
    }
}

