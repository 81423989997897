.innovationCard {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    gap: 35px;
    transition: all .5s ease;
    min-height: 540px;
    height: 100%;
}


.innovationCard:hover {
    /* background: linear-gradient(to top, rgba(51, 81, 203,.2) 0%, rgba(255, 255, 255, 0.05) 30%) !important; */
    background: linear-gradient(to top, rgba(102, 159, 234, 0.3) 0%, rgba(255, 255, 255, 0.05) 40%);
}

.innovationCard .cardTitle {
    position: relative;
}

.innovationCard .cardTitle h3 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 1.3;
    margin: 0;
    min-height: 70px;
}

.innovationCard .cardDis {
    position: relative;
}

.innovationCard .cardDis p {
    padding-top: 30px;
    border-top: 1px solid #fff;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.6;
}

.cardIcon {
    width: 60px;
    height: 60px;
}

.cardIcon img {
    max-width: 100%;
    object-fit: contain;
}

@media(max-width:600px) {
    .innovationCard {
        min-height: auto !important;
    }

    .innovationCard .cardTitle h3 {
        font-size: 18px;
        min-height: auto;
    }

    .innovationCard .cardDis p {
        font-size: 14px;
    }
}