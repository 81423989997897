.Faqs {
    position: relative;
    z-index: 100;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}

.faqTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    font-size: 41.036px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    width: 100%;
    cursor: pointer;
    position: relative;
    padding: 0 10px;
}

.faqTitle::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #0B3064 48%, rgba(153, 153, 153, 0.00) 100%);
}

.faqTitle span {
    transition: all .3s;
}

.faqTitle span.rotate {
    transition: all .3s;
    transform: rotate(90deg);
}

.rotate_ar {
    transition: all .3s;
    transform: rotate(180deg);
}



.faqDescription {
    max-height: 0;
    padding: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    position: relative;
    /* z-index: 10000; */
}

.showDescription {
    max-height: 150vh;
    padding: 40px 0;
    overflow-y: auto;
}

@media(max-width:767px) {
    .faqTitle {
        font-size: 32px;
    }
        .rotate svg,
        .rotate_ar svg {
            width: 28px;
            height: 28px;
        }
}

@media(max-width:500px) {
    .faqTitle {
        font-size: 18px;
    }
        .rotate svg,
        .rotate_ar svg {
            width: 24px;
            height: 24px;
        }
}

@media(max-width:400px) {
    .faqTitle {
        font-size: 18px;
    }
}