.TechnoCard {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    border: 0.5px solid rgba(255, 255, 255, 0.52);
    height: 200px;
    margin-bottom: 25px;
    overflow: hidden;
}

.TechnoCard img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

@media(min-width:994px) {
    .technolist {
        margin-top: 50px;
    }
}

@media(max-width:767px) {
    .TechnoCard {
        padding: 20px;
        height: 100px;
    }
}