.ContactHeader {
    padding: 100px 0 50px;
    /* background: linear-gradient(to top, #122747d1, #0b1d34); */
}

.ContactHeader .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ContactHeader h2 {
    text-align: center;
    font-size: 48px;
    font-style: italic;
    width: 100%;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(to bottom right, #53D4AC 0%, #3351CB 39%, #3351CB 49%) bottom right / 50% 50% no-repeat,
        linear-gradient(to bottom left, #53D4AC 0%, #3351CB 39%, #3351CB 49%) bottom left / 50% 50% no-repeat,
        linear-gradient(to top left, #53D4AC 0%, #3351CB 39%, #3351CB 49%) top left / 50% 50% no-repeat,
        linear-gradient(to top right, #53D4AC 0%, #3351CB 39%, #3351CB 49%) top right / 50% 50% no-repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.ContactHeader p {
    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.6;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

@media(min-width:994px) {
    .ContactHeader p {
        width: 80%;
    }
}

@media(max-width:767px) {
    .ContactHeader {
        padding: 50px 0 10px;
        /* background: linear-gradient(to top, #122747d1, #0b1d34); */
    }
    .ContactHeader h2 {
        font-size: 24px;
    }

    .ContactHeader p {
        font-size: 12px;
    }
}

