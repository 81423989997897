.Sector {
    position: relative;
    padding: 100px 0;
    width: 100%;
    background: linear-gradient(to top, #122747d1, #0b1d34);
    direction: ltr;
}

.Sector::before {
    content: '';
    position: absolute;
    top: -30%;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/sectorBg.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .8;
}
@media(max-width:767px){
    .Sector{
        padding: 40px 0 100px;
    }
}