.ServiceCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.serviceIcon {
    display: flex;
    justify-content: flex-start;
    align-items: start;
}

.iconCard {
    padding: 8px;
    background: url('../../../../../assets/images/iconBg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    max-width: 50px;
}

.iconCard img {
    max-width: 100%;
}

.serviceTitle h4 {
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: start;
}

.serviceDesc p {
    color: #E2E2E2;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.429px;
    opacity: .8;
    text-align: start;
}
@media(max-width:994px){
    .ServiceCard{
        align-items: flex-start;
        
    }
        .serviceDesc p{
            text-align: start;
        }
}
@media(max-width:767px) {
    .serviceTitle h4 {
        font-size: 22px;
    }

    .serviceDesc p {
        font-size: 16px;
    }
}
@media(max-width:400px) {
    .serviceTitle h4 {
        font-size: 18px;
    }

    .serviceDesc p {
        font-size: 14px;
    }
}