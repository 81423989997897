.SectorCard {
    position: relative;
    padding: 75px 20px 75px 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 500px !important;
}

.SectorCard_ar {
    direction: rtl;
    padding: 75px 50px 75px 0px;
}

.SectorCard::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.78);
}

.sectorContent {
    z-index: 10000;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
}

.sectorIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 42px;
}

.sectorIcon img {
    max-width: 100%;
}

.sectorTitle h4 {
    color: #61B3FF;
    font-size: 26.107px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.sectorDes p {
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 129.9%;
}




.sector-swipe-container {
    position: relative;
    width: 100%;
    width: 600px;
    /* height: 522.846px !important; */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    /* gap: 100px; */
}

.custom-prev,
.custom-next {
    position: absolute;
    border: none;
    outline: none;
    background: transparent;
    border-radius: 25px;
    border: 1px solid #9c9c9c;
    cursor: pointer;
    padding: 10px;
}


@media(min-width:994px) {
    .custom-prev {
        left: -120px;
    }

    .custom-next {
        right: -120px;
    }
}

@media(max-width:993px) {
    .custom-prev {
        bottom: -80px !important;
        left: 40%;
        transform: translateX(-40%);
    }

    .custom-next {
        bottom: -80px !important;
        right: 30%;
        transform: translateX(-30%);
    }
}

@media(max-width:767px) {
    .sector-swipe-container {
        width: 100%;
    }

    .SectorCard {
        padding: 50px 20px;
        min-height: 470px !important
    }

}

@media(max-width:600px) {
    .sectorTitle h4 {
        font-size: 24px;
    }

    .sectorDes p {
        font-size: 18px;
    }
}

@media(max-width:400px) {
    .sectorTitle h4 {
        font-size: 20px;
    }

    .sectorDes p {
        font-size: 15px;
    }

    .custom-prev {
        bottom: -80px !important;
        left: 30%;
        transform: translateX(-30%);
    }

    .custom-next {
        bottom: -80px !important;
        right: 20%;
        transform: translateX(-20%);
    }
}