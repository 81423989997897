.Technology {
    overflow: hidden;
    padding: 80px 0;
    position: relative;
    background: linear-gradient(to bottom, #122747d1, #0b1d34);
    /* background: linear-gradient(270deg, rgba(23, 53, 92, 0.33) -63.15%, rgba(59, 133, 228, 0.33) 118.05%);; */
}

.wave {
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/images/Wave.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .2;
    overflow: hidden;
}

@media(max-width:767px) {
    .Technology {
        padding: 60px 0;
    }
}