.txtinn {
    display: inline-block;
    background: linear-gradient(to bottom right, #53D4AC 0%, #3351CB 39%, #3351CB 49%) bottom right / 50% 50% no-repeat,
        linear-gradient(to bottom left, #53D4AC 0%, #3351CB 39%, #3351CB 49%) bottom left / 50% 50% no-repeat,
        linear-gradient(to top left, #53D4AC 0%, #3351CB 39%, #3351CB 49%) top left / 50% 50% no-repeat,
        linear-gradient(to top right, #53D4AC 0%, #3351CB 39%, #3351CB 49%) top right / 50% 50% no-repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 10px;
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.txtinn h2 {
    text-align: center;
    font-size: 90px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
    padding: 0;
}

.txtinn p {
    text-align: center;
    font-size: 34px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.3;
    width: 100%;
}

@media(max-width:994px) {
    .txtinn h2 {
        font-size: 52px;
    }

    .txtinn p {
        font-size: 24px;
        margin-top: 15px;
    }
}

@media(max-width:767px) {
    .txtinn h2 {
        font-size: 32px;
    }

    .txtinn p {
        font-size: 18px;
    }
}

@media(max-width:400px) {
    .txtinn h2 {
        font-size: 22px;
    }

    .txtinn p {
        font-size: 14px;
        margin-top: 15px;
    }
}