.HeroContact {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 70vh;
    background: url('../../../assets/images/contactHero.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.HeroContact::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.89) 0%, rgba(0, 0, 0, 0.51) 100%);
}

.HeroContact h1 {
    margin: auto;
    border: 2px solid #FFF;
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 150px;
    width: fit-content;
    position: relative;
    z-index: 1;
}

@media(max-width:767px) {
    .HeroContact {
        height: 30vh;
    }

    .HeroContact h1 {
        top: 25px;
        font-size: 18px;
        padding: 15px 40px;
    }
}
