.ContactInfo {
    padding: 100px 0 150px;
}

.ContactInfo .container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.listInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 50px; */
}

.listInfo .info {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    text-decoration: none;
    /* width: fit-content !important; */
}

.info h3 {
    color: #FFF;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 !important;
}

.info span {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.listInfo .info:not(:last-of-type) ::after {
    content: '';
    position: absolute;
    top: -40%;
    right: 0px;
    width: 1.077px;
    height: 290px;
    background: linear-gradient(rgba(255, 255, 255, 0.00) 0%, #0B3064 48%, rgba(153, 153, 153, 0.00) 100%);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1000;
}

.listInfo_ar .info:not(:last-of-type) ::after {
    right: auto;
    left: 0px !important;
}

@media(max-width:994px) {
    .iconInfo svg {
        width: 40px;
        height: 40px;
    }
}

@media(max-width:767px) {
    .ContactInfo {
        padding: 50px 0 100px;
    }

    .listInfo .info:not(:last-of-type) ::after {
        display: none;
    }

    .iconInfo svg {
        width: 30px;
        height: 30px;
    }

    .info h3 {
        font-size: 18px;
    }

    .info span {
        font-size: 14px;
    }

    .listInfo {
        /* flex-direction: column; */
        gap: 40px;
        align-items: start !important;
    }

    .listInfo .info {
        gap: 20px;
    }
}

@media(max-width:767px) {
    .listInfo {
        flex-direction: column !important;
        align-items: start !important;
        gap: 30px;
    }
.info{
    flex-direction: row !important;
}
    .info h3 {
        font-size: 18px;
    }

    .info span {
        font-size: 14px;
    }

    .listInfo .info {
        flex: 1;
        gap: 16px;
    }
}