.innovation {
    position: relative;
    padding: 80px 0;
    background: linear-gradient(#122747, #0b1d34);
    background: linear-gradient(270deg, #1227477A, #0b1d347A);
}

.innpvationContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.innpvationContent .txtinn h2 {
    text-align: center;
    font-size: 53px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin: 0;
}

.innpvationContent .txtinn {
    display: inline-block;
    background: linear-gradient(to bottom right, #53D4AC 0%, #3351CB 39%, #3351CB 49%) bottom right / 50% 50% no-repeat,
        linear-gradient(to bottom left, #53D4AC 0%, #3351CB 39%, #3351CB 49%) bottom left / 50% 50% no-repeat,
        linear-gradient(to top left, #53D4AC 0%, #3351CB 39%, #3351CB 49%) top left / 50% 50% no-repeat,
        linear-gradient(to top right, #53D4AC 0%, #3351CB 39%, #3351CB 49%) top right / 50% 50% no-repeat;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
}

@media(max-width:767px){
    .innovation{
        padding: 40px 0;
    }
}

@media(max-width:994px) {
    .innpvationContent .txtinn h2 {
        font-size: 32px;
    }

    .innpvationContent .txtinn p {
        font-size: 16px;
    }
}
@media(max-width:767px) {
    .innpvationContent .txtinn h2 {
        font-size: 24px;
    }

    .innpvationContent .txtinn p {
        font-size: 16px;
    }
}

@media(max-width:400px) {
    .innpvationContent .txtinn h2 {
        font-size: 18px;
    }

    .innpvationContent .txtinn p {
        font-size: 12px;
        margin-top: 15px;
    }
}