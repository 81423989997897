.Hero {
    position: relative;
    width: 100%;
    height: 100vh;
    /* direction: ltr !important; */
    /* background: #fff; */
}

.hero-swiper .swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid #fff;
    opacity: 0.8;
    width: 12px;
    height: 12px;
}

.hero-swiper .swiper-slide::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .3;

}

.hero-swiper .swiper-pagination-bullet-active {
    background-color: #fff;
    border-color: #fff;
    opacity: 1;
    width: 12px;
    height: 12px;
}

.hero-swiper .slide-content {
    position: relative;
    left: 100px;
    top: 60%;
    transform: translateY(-50%);
    color: #fff;
    width: 600px;
    height: fit-content;
    z-index: 1000;
    border-radius: 40px;
    padding: 20px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    /* background-color: #000; */

    border: 3px solid transparent;
    /* background-color: rgba(0,0,0,.2); */
}

.slide-content-ar {
    direction: rtl !important;
    left: 0 !important;
    right: 100px !important;
}

.hero-swiper .slide-content:hover {
    box-shadow: 0 0 20px rgba(0, 255, 255, .4);
    /* border-color: rgba(255, 255, 255, .3); */
}

.hero-swiper .slide-content::before {
    content: '';
    position: absolute;
    top: var(--top);
    left: var(--left);
    transform: translate(-50%, -50%);
    background: radial-gradient(circle, rgba(0, 255, 255, 0.2), transparent);
    height: 500px;
    width: 500px;
    opacity: 0;
    transition: 0.5s, top 0s, left 0s;
}

.hero-swiper .slide-content:hover::before {
    opacity: 1;
    /* z-index: -1 !important; */
}

.hero-swiper .slide-content::after {
    content: '';
    position: absolute;
    inset: 1px;
    border-radius: 40px;
    z-index: 1;
    /* background: #fff; */
    opacity: .1;
}

.hero-swiper h2 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: bold;
}

.hero-swiper p {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    font-weight: 300;
}

.hero-swiper a {
    /* margin-top: 20px; */
    display: block;
    text-decoration: none;
    padding: 15px 40px;
    background-color: #d9d9d9;
    border: none;
    color: #0A2B47;
    cursor: pointer;
    border-radius: 12px;
    width: fit-content;
    margin-top: 20px;
    position: relative;
    z-index: 10000;
}

@media(max-width:767px) {

    .hero-swiper .slide-content {
        left: 0 !important;
        width: 95%;
        margin-left: 2.5%;
    }

    .slide-content-ar {
        right: 0 !important;
        width: 95%;
        margin-right: 2.5%;
    }
}

@media(max-width:600px) {
    .hero-swiper h2 {
        font-size: 1.5rem;
    }

    .hero-swiper p {
        font-size: .75rem;
    }
        .hero-swiper a{
            padding: 8px 20px;
        }
}