@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Cairo:wght@200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root {
  --color-primary: #0A2B47;
  --color-secondary: #E3BF5C;
  --color-text: #fff;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-family: "Archivo",
    sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  vertical-align: middle;
}

body {
  font-family: "Archivo",
    sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  min-height: 100vh;
  /* overflow-x: hidden !important; */
  background: #17202f !important;
  color: #fff !important;
  /* background: linear-gradient(270deg, rgba(23, 53, 92, 0.33) -63.15%, rgba(59, 133, 228, 0.33) 118.05%); */
}

ul {
  list-style: none;
  margin: 0 !important;
  padding: 0 !important;
}

li {
  margin: 0;
  padding: 0;
}

/* LoadingPage.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #2db7e4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

main {
  position: relative;
  top: 0;
  left: 0;
  overflow-x: hidden !important;;
}