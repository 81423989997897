.ServiceBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    width: 100%;
}

.topService {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 10px;
}

.topService::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(268.94% 50% at 50% 50%, rgba(19, 37, 63, 0.88) 0%, rgba(0, 0, 0, 0.54) 100%);
}

.topService span {
    color: #FFF;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.topService p {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.3;
    z-index: 100;
}

@media(min-width:994px) {
    .topService p {
        width: 60%;
    }
}
@media(max-width:767px){
    .topService span{
        font-size: 22px;
    }
    .topService p {
        font-size: 16px;
    }
}
@media(max-width:550px){
    .topService span{
        font-size: 18px;
    }
    .topService p {
        font-size: 14px;
    }
}